define("ember-ref-bucket/utils/ref", ["exports", "@ember/destroyable", "@glimmer/tracking"], function (_exports, _destroyable, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bucketFor = bucketFor;
  _exports.getNodeDestructors = getNodeDestructors;
  _exports.registerNodeDestructor = registerNodeDestructor;
  _exports.resolveGlobalRef = resolveGlobalRef;
  _exports.setGlobalRef = setGlobalRef;
  _exports.unregisterNodeDestructor = unregisterNodeDestructor;
  _exports.watchFor = watchFor;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let lastGlobalRef = null;
  const buckets = new WeakMap();
  const nodeDestructors = new WeakMap();
  let FieldCell = (_class = class FieldCell {
    constructor() {
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  function setGlobalRef(value) {
    lastGlobalRef = value;
  }
  function resolveGlobalRef() {
    return lastGlobalRef;
  }
  function createBucket() {
    return {
      bucket: {},
      keys: {},
      createTrackedCell(key) {
        if (!(key in this.keys)) {
          this.keys[key] = new FieldCell();
        }
      },
      get(name) {
        this.createTrackedCell(name);
        return this.bucket[name] || null;
      },
      dirtyTrackedCell(name) {
        this.createTrackedCell(name);
        const val = this.keys[name].value;
        this.keys[name].value = val;
      },
      getTracked(name) {
        this.createTrackedCell(name);
        return this.keys[name].value;
      },
      add(name, value) {
        this.createTrackedCell(name);
        this.keys[name].value = value;
        this.bucket[name] = value;
        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }
        this.notificationsFor[name].forEach(fn => fn());
      },
      addNotificationFor(name, fn) {
        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }
        this.notificationsFor[name].push(fn);
        return () => {
          this.notificationsFor[name] = this.notificationsFor[name].filter(cb => cb !== cb);
        };
      },
      notificationsFor: {}
    };
  }
  function getNodeDestructors(node) {
    return nodeDestructors.get(node) || [];
  }
  function registerNodeDestructor(node, cb) {
    if (!nodeDestructors.has(node)) {
      nodeDestructors.set(node, []);
    }
    nodeDestructors.get(node).push(cb);
  }
  function unregisterNodeDestructor(node, cb) {
    const destructors = nodeDestructors.get(node) || [];
    nodeDestructors.set(node, destructors.filter(el => el !== cb));
  }
  function bucketFor(rawCtx) {
    const ctx = rawCtx;
    if (!buckets.has(ctx)) {
      buckets.set(ctx, createBucket());
      if ((0, _destroyable.isDestroyed)(ctx) || (0, _destroyable.isDestroying)(ctx)) {
        try {
          return buckets.get(ctx);
        } finally {
          buckets.delete(ctx);
        }
      }
      (0, _destroyable.registerDestructor)(ctx, () => {
        buckets.delete(ctx);
      });
    }
    return buckets.get(ctx);
  }
  function watchFor(name, bucketRef, cb) {
    const bucket = bucketFor(bucketRef);
    return bucket.addNotificationFor(name, cb);
  }
});